import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "global",
  initialState: {
    productTypes: [],
    subProductTypes:[],
    countries: [],
    currencies: [],
    offerBaseTab: "",
    advancedSearchParams: {},
    companyCommonInfo: {},
    appVersion: "1.0.0",
    multiSearchData:{},
    whatsNewOpened: false,
  },
  reducers: {
    setProductTypes(state, action) {
      state.productTypes = action.payload.productTypes;
    },
    setSubProductTypes(state, action) {
      state.subProductTypes = action.payload.subProductTypes;
    },
    setCurrencies(state, action) {
      state.currencies = action.payload.currencies;
    },
    setOfferBaseTab(state, action) {
      state.offerBaseTab = action.payload;
    },
    setAdvancedSearchParams(state, action) {
      state.advancedSearchParams = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload.countries;
    },
    setCompanyCommonInfo(state, action) {
      state.companyCommonInfo = action.payload.companyInfo;
    },
    setAppVersion(state, action) {
      state.appVersion = action.payload;
    },
    setWhatsNewOpened(state, action) {
      state.whatsNewOpened = action.payload;
    },
    setMultiSearchData(state, action) {
      state.multiSearchData= action.payload;
    }
  },
});

export const {
  setProductTypes: setProductTypesAction,
  setSubProductTypes: setSubProductTypesAction,
  setCurrencies: setCurrenciesAction,
  setOfferBaseTab: setOfferBaseTabAction,
  setAdvancedSearchParams: setAdvancedSearchParamsAction,
  setCountries: setCountriesAction,
  setCompanyCommonInfo: setCompanyCommonInfoAction,
  setAppVersion: setAppVersionAction,
  setWhatsNewOpened: setWhatsNewOpenedAction,
  setMultiSearchData: setMultiSearchDataAction,
} = slice.actions;

export const setProductTypes = (productTypes) => async (dispatch) => {
  dispatch(setProductTypesAction({ productTypes }));
};
export const setSubProductTypes = (subProductTypes) => async (dispatch) => {
  dispatch(setSubProductTypesAction({ subProductTypes }));
};

export const setCurrencies = (currencies) => async (dispatch) => {
  dispatch(setCurrenciesAction({ currencies }));
};

export const setOfferBaseTab = (baseTab) => async (dispatch) => {
  dispatch(setOfferBaseTabAction(baseTab));
};

export const setCountries = (countries) => async (dispatch) => {
  dispatch(setCountriesAction({ countries }));
};

export const setCompanyCommonInfo = (companyInfo) => async (dispatch) => {
  dispatch(setCompanyCommonInfoAction({ companyInfo }));
};


export const setWhatsNewOpened = (opened) => async (dispatch) => {
  dispatch(setWhatsNewOpenedAction(opened));
};

export const setAppVersion = (version) => async (dispatch) => {
  dispatch(setAppVersionAction(version));
};
export const setMultiSearchData = (multiSearchData) => async (dispatch) => {
  dispatch(setMultiSearchDataAction(multiSearchData));
};


export default slice.reducer;

