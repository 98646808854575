import { combineReducers } from "redux";

import auth from "./auth";
import route from "./route";
import global from "./global";
import chat from "./chat";
import cart from "./cart";
import notification from "./notification";
import preferences from "./preferences";
import aiInvoice from "@reducers/ai-invoice";

export default combineReducers({
  auth,
  route,
  global,
  chat,
  cart,
  notification,
  preferences,
  aiInvoice,
});
