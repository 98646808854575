import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import withRedux from "next-redux-wrapper";
import { useSelector, useDispatch, Provider } from "react-redux";
import * as Sentry from "@sentry/nextjs";
import styledNormalize from "styled-normalize";
import "react-notifications/lib/notifications.css";
import { withRouter } from "next/router";
import App from "next/app";
import createStore from "store/createStore";
import Layout from "components/Layout";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import theme from "theme";
import "toastr/build/toastr.min.css";
import "nprogress/nprogress.css";
import "./styles.scss";
import { companyLoaded, setCompany, setUser, signout } from "@reducers/auth";
import { getMe, registerAmityInDashboard } from "../src/services/auth";
import { initializeFirebase, firebase, analytics } from "@services/firebase";
import { initializeGleap, indentifyGleap } from "@services/gleap";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "@services/theme";
import { useEffect, useState, useRef, useMemo } from "react";
import LoadingIndicator from "@components/LoadingIndicator";
import toastr from "toastr";
import { authStorage } from "@services/authStorage";
import {
  getProductTypes,
  getCurrencies,
  getCountriesGeo,
  infoService,
  getSubTypesWithProductTypeId,
} from "@services/info";

import {
  setProductTypes,
  setCurrencies,
  setCountries,
  setCompanyCommonInfo,
  setSubProductTypes,
} from "@reducers/global";
import { firebaseCloudMessaging } from "@services/webPush";
import { createDeviceToken } from "@services/notification";
import {
  NOTIFICATION_ACTION_PARENT_TYPE_ENUM,
  COMPANY_STATUS_ENUM,
} from "../src/constants";
import { setNewChatInfo } from "@reducers/chat";
import { useRouter } from "next/router";
import { getCompany } from "@services/company";
import { assignOfferWithRefCode } from "@services/offer";
initializeFirebase();

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
`;

toastr.options = {
  closeButton: true,
  positionClass: "toast-top-center",
  timeOut: 3000,
  preventDuplicates: true,
};

function MyApp({ Component, pageProps, router, store }) {
  const routers = useRouter();

  useEffect(() => {
    const logEvent = (url) => {
      // analytics().setCurrentScreen(url);
      analytics().logEvent("screen_view", {
        screen_name: url,
      });
      analytics().logEvent("login", {
        screen_name: url,
      });
    };

    routers.events.on("routerChangeComplete", logEvent);

    logEvent(window.location.pathname);

    return () => {
      routers.events.off("routeChangeComplete", logEvent);
    };
  }, [routers.events]);

  return (
    <>
      <Helmet>
        <title>FruPro</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="FruPro" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <Provider store={store}>
            <GlobalStyle />
            <AuthLayout {...pageProps} store={store}>
              <NotificationContainer />
              <Layout {...pageProps} router={router}>
                <Component router={router} {...pageProps} />
              </Layout>
            </AuthLayout>
          </Provider>
        </MuiThemeProvider>
      </ThemeProvider>
    </>
  );
}

function AuthLayout({ children, isPublic, store }) {
  let [user, setUser] = useState(null);
  const userStoreData = useSelector((state) => state.auth.user);
  const isLoaded = useSelector((state) => state.auth.isCompanyLoading);
  const userStoreDataRef = useRef();
  userStoreDataRef.current = userStoreData;
  const dispatch = useDispatch();
  const router = useRouter();
  const offerBaseTab = useSelector((state) => state.global.offerBaseTab);

  const User = useSelector((state) => state.auth.user);

  const isDashBoard = useMemo(() => router.pathname === "/");

  const offerRefCode = router.query?.refCode;

  const finalRefCode =
    typeof window !== "undefined" && localStorage.getItem("offerRefCode");

  const isRestrictUser = useMemo(() => {
    const restrictNormalUser =
      !userStoreData?.companyId && userStoreData?.type !== "COMPANY_ADMIN";

    const restrictAdmin =
      userStoreData?.companyStatus === COMPANY_STATUS_ENUM.PENDING &&
      userStoreData?.type === "COMPANY_ADMIN";

    return restrictNormalUser || restrictAdmin;
  }, [userStoreData]);

  useEffect(() => {
    if (offerRefCode) {
      localStorage.setItem("offerRefCode", offerRefCode);
    }
  }, [offerRefCode]);

  useEffect(() => {
    if (userStoreData?.id && userStoreData?.companyId) {
      const getCompanyInfo = async () => {
        dispatch(companyLoaded(true));
        const response = await getCompany(userStoreData?.companyId);
        dispatch(companyLoaded(false));

        if (response) {
          dispatch(setCompany(response));
        }
      };
      getCompanyInfo();
    }
  }, [userStoreData?.id]);

  useEffect(() => {
    if (userStoreData?.id && userStoreData?.companyId && finalRefCode) {
      const assignOffer = async () => {
        const response = await assignOfferWithRefCode(finalRefCode);
        if (response) {
          localStorage.removeItem("offerRefCode");
        }
      };
      assignOffer();
    }
  }, [userStoreData?.id, userStoreData?.companyId, finalRefCode]);

  useEffect(() => {
    // always triggered when token updated
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        setTimeout(async () => {
          await firebase
            .auth()
            .currentUser.getIdToken()
            .then(function (idToken) {
              if (authStorage.token !== idToken) {
                // if token update, save to authStorage
                authStorage.token = idToken;

                // if redux's user data is blank, reload webpage to reload user data (when firebase token expired after more than 1 hour inactive)
                if (!userStoreDataRef.current) {
                  window.location.reload();
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          setUser(user);

          // Sentry.setUser({ email: user.email });
          if (User?.isVerified === true) {
            if (localStorage.getItem("prevPage")) {
              const page = localStorage.getItem("prevPage");

              if (!page.includes("/verify-signup")&&!page.includes("/new-password")) {
                localStorage.setItem("prevPage", "");
                router.push(page);
              }
            }
          }
        }, 100);
      } else if (!user && isPublic) {
        setUser(undefined);

        // redirectLogin();
      } else {
        setUser(undefined);
        // redirectLogin();
      }
    });
  }, [User]);

  useEffect(() => {
    if (!User && window.location.pathname !== "/login") {
      let pathName = window?.location.pathname;
      const params = window?.location.search;
      pathName = params ? pathName + params : pathName;
      localStorage.setItem("prevPage", pathName);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setToken();
      async function setToken() {
        try {
          const token = await firebaseCloudMessaging.init();

          console.log(token);

          if (token && userStoreData && userStoreData?.isVerified) {
            await createDeviceToken(token);
            getMessage();
          }
        } catch (error) {
          console.log(error);
        }
      }

      function getMessage() {
        const messaging = firebase.messaging();
        messaging.onMessage((message) => {
          onHandleMessage(message);
        });
      }

      function onHandleMessage(message) {
        let notification = JSON.parse(message.data?.notification);
        const { title, body } = notification;
        const { data } = message;

        const options = {
          body,
          icon: `/static/images/app-icon.png`,
          vibrate: [200, 100, 200, 100, 200, 100, 200],
        };

        if ("serviceWorker" in navigator) {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then(function (registration) {
              console.log("Service Worker Registered");
              setTimeout(() => {
                let notification = new Notification(title, options);
                NotificationManager.info(
                  notification.body,
                  notification.title,
                  5000,
                  () => router.push("/notifications")
                );
                onTrigger(notification, data);
              }, 100);
            })
            .catch(function (err) {
              console.log("Service Worker Failed to Register", err);
            });
        }
      }

      function onTrigger(notification, data) {
        let offer = "";
        let invoice = "";
        if (data?.offer) {
          offer = JSON.parse(data?.offer);
        }
        if (data?.invoice) {
          invoice = JSON.parse(data?.invoice);
        }
        let path = "/notification";
        let currentUSerCompanyId = userStoreDataRef.current?.companyId;
        let pathRefresh = [
          "/my-colleagues",
          "/purchases/orders-placed",
          "/sales/orders-received",
          "/purchases/orders-placed/[id]",
          "/sales/orders-received/[id]",
          "/notification",
        ];
        let reloadTimeout = "";

        switch (NOTIFICATION_ACTION_PARENT_TYPE_ENUM[data.actionType]) {
          case "offer":
            if (offer) {
              // check user's companyId exists in offer data
              if (
                currentUSerCompanyId &&
                [offer?.buyerCompanyId, offer?.sellerCompanyId].includes(
                  currentUSerCompanyId
                )
              ) {
                if (currentUSerCompanyId === offer?.buyerCompanyId) {
                  path = `/purchases/orders-placed/${offer?.id}`;
                } else {
                  path = `/sales/orders-received/${offer?.id}`;
                }
              }
            }
            break;
          case "invoice":
            if (invoice) {
              // check user's companyId exists in invoice data
              if (
                currentUSerCompanyId &&
                [offer?.buyerCompanyId, offer?.sellerCompanyId].includes(
                  currentUSerCompanyId
                )
              )
                path = `/sales/invoices-issued/${invoice?.id}?offerId=${invoice?.offerId}`;
            }
            break;
          case NOTIFICATION_ACTION_PARENT_TYPE_ENUM.CREATE_MESSAGE:
            path = () =>
              dispatch(
                setNewChatInfo({
                  onlyToggleChatPanel: true,
                })
              );
            break;
          default:
            break;
        }
        // if currently stay on this page, reload it
        if (router?.asPath.includes(path)) {
          // router.reload();
          notification.onclick = function (event) {
            event.preventDefault();
          };
        } else {
          notification.onclick = function (event) {
            event.preventDefault();

            // dispatch open chat panel when receive CREATE_MESSAGE notification type
            if (path instanceof Function) return path();
            if (path) router.push(path);
            clearTimeout(reloadTimeout);
          };
          // refresh list notification, list invoice, list offer, list employee
          if (pathRefresh.includes(router.pathname)) {
            reloadTimeout = setTimeout(() => {
              notification.close();
              router.reload();
            }, 2000);
          }
        }
      }
    }
  }, [user, offerBaseTab, userStoreData]);

  // useEffect(() => {
  //   if (userStoreData && userStoreData?.isVerified) {
  //     initializeGleap();
  //     indentifyGleap(userStoreData);
  //   }
  // }, [userStoreData]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebase.auth().currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  if (user === null) return <LoadingIndicator loading />;

  // if (user && isDashBoard && isRestrictUser) {
  //   return null;
  // }

  return children;
}

MyApp.getInitialProps = async (appContext) => {
  const {
    ctx: { store, req, res },
  } = appContext;
  if (!req) {
  } else {
    let token = req?.cookies?.token;

    if (token) {
      const [userData, productTypes, subProductTypes,currencies, countries, companyCommonInfo] =
        await Promise.allSettled([
          getMe(token),
          getProductTypes(),
          getSubTypesWithProductTypeId(),
          getCurrencies({ token }),
          getCountriesGeo(),
          infoService.getCompanyTypes(),
        ]);

      userData.status == "fulfilled" &&
        store.dispatch(setUser(userData.value.data));
      productTypes.status == "fulfilled" &&
        store.dispatch(setProductTypes(productTypes.value || []));
        subProductTypes.status == "fulfilled" &&
        store.dispatch(setSubProductTypes(subProductTypes.value?.data || []));
      currencies.status == "fulfilled" &&
        store.dispatch(setCurrencies(currencies.value || []));
      countries.status === "fulfilled" &&
        store.dispatch(setCountries(countries.value));
      companyCommonInfo.status === "fulfilled" &&
        store.dispatch(setCompanyCommonInfo(companyCommonInfo.value));
    } else {
      const [productTypes, countries, companyCommonInfo] =
        await Promise.allSettled([
          getProductTypes(),
          getCountriesGeo(),
          infoService.getCompanyTypes(),
        ]);

      productTypes.status == "fulfilled" &&
        store.dispatch(setProductTypes(productTypes.value || []));
      countries.status === "fulfilled" &&
        store.dispatch(setCountries(countries.value));
      companyCommonInfo.status === "fulfilled" &&
        store.dispatch(setCompanyCommonInfo(companyCommonInfo.value));
    }
  }

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default withRedux(createStore)(withRouter(MyApp));
