import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "aiInvoice",
  initialState: {
    invoiceData: null,
  },
  reducers: {
    setAIInvoiceData(state, action) {
      console.log("payload:", action.payload);
      state.invoiceData = action.payload;
    },
  },
});

export const { setAIInvoiceData } = slice.actions;

export default slice.reducer;
