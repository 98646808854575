import React, { useEffect, useMemo, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import styled from "styled-components";
import { FruProBreadcrumbs } from "@components";
import Message from "@containers/Message/MessageContainer";
import { useSelector } from "react-redux";
import router from "next/router";
import localforage from "localforage";
import FruProDialog from "@components/Dialog";
import { Typography, Box } from "@material-ui/core";
import DownloadApp from "@containers/download-app/DownloadApp";
import { COMPANY_STATUS_ENUM } from "@constants";
import { useDispatch } from "react-redux";
import FloatingButton from "@components/FloatingButton/FloatingButton";

const Root = styled.div`
  background: #f8f8f8;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainBox = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(./static/images/orange_main_blur.svg);
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    justify-content: space-between;
    background-position: top right;
    object-fit: cover;
    background-size: 60%;
    backdrop-filter: blur(6px);
    height: 100%;

  //* Only Subscribe screen this settings
  @media (max-width: 768px) {
    background-size: 105%;
  }
`;

const Body = styled.div`
  // padding: 0 140px;
`;

const ModalContainer = styled.div`
  padding: 16px;
  text-align: center;
`;

const CustomText = styled(Typography)({
  color: "#E77228",
  textDecoration: "underline",
  fontWeight: 500,
});

const StrictMessageContainer = styled.div`
  position: absolute;
  top: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  & > p {
    padding: 8px;
    background: #f4c327;
    font-weight: 500;
    border-radius: 6px;
  }
`;

export default function Index(props) {
  const user = useSelector((state) => state.auth.user);
  const isBusinessUser = useMemo(() => user?.companyId, [user]);
  const dispatch = useDispatch();

  const isRestrictUser = useMemo(() => {
    return (
      !user?.companyId ||
      (user?.type === "COMPANY_ADMIN" &&
        user?.companyStatus === COMPANY_STATUS_ENUM.PENDING)
    );
  }, [user]);

  const isDashboard = useMemo(() => router.pathname === "/");
  const isSignUp = useMemo(
    () =>
      router.pathname === "/login" ||
      router.pathname === "/sign-up" ||
      router?.pathname === "/company-registration" ||
      router.pathname === "/forgot-password" ||
      router.pathname === "/subscribe"
  );
  const isRemoveBreadCrumb = useMemo(
    () =>
      router.pathname === "/connection-explore" ||
      router.pathname === "/my-company-profile" ||
      router?.pathname === "/produce-explore" ||
      router.pathname === "/my-user-profile" ||
      router?.pathname === "/company-directory" ||
      router?.pathname === "/my-credit-history" ||
      router?.pathname === "/demanded-products" ||
      router?.pathname === "/settings" ||
      router?.pathname === "/notifications"||
      router?.pathname === "/new-password"
  );
  const isNotFound = useMemo(() => router.pathname === "/404");

  const [showedCookieConsent, setShowedCookieConsent] = useState(true);

  const getCookieConsent = async () => {
    let showedCookieConsent = await localforage.getItem("showedCookieConsent");
    setShowedCookieConsent(showedCookieConsent);
  };

  useEffect(() => {
    getCookieConsent();
  }, []);

  const onAccept = () => {
    localforage.setItem("showedCookieConsent", true);
    setShowedCookieConsent(true);
  };

  const renderRestrictMessage = () => {
    if (
      user?.type === "COMPANY_ADMIN" &&
      user?.companyStatus === COMPANY_STATUS_ENUM.PENDING &&
      !isDashboard &&
      user?.companyId
    ) {
      return (
        <StrictMessageContainer>
          <Typography>
            A FruPro admin will need to approve your company registration before
            you can begin trading & messaging
          </Typography>
        </StrictMessageContainer>
      );
    }

    if (user && user?.type === "COMPANY_ADMIN" && !user?.companyId) {
      return (
        <StrictMessageContainer>
          <Typography>
            Please fill in your company information to finish your registration!
          </Typography>
        </StrictMessageContainer>
      );
    }

    return null;
  };

  const arrayButtons = [
    {
      onClick: () => {
        // still accept
        onAccept();
        router.push("/cookie-policy");
      },
      color: "#fff",
      text: <CustomText>More Information</CustomText>,
    },
    {
      onClick: () => onAccept(),
      color: "primary",
      text: "Accept",
    },
  ];

  if (isNotFound)
    return (
      <Root>
        <Body>{props.children}</Body>
      </Root>
    );

  // if (!user && !isDashboard) {
  //   console.log("girdi")

  // }

  const isExcludePage = router.pathname === "/mdu/[id]";

  return (
    <>
      {isSignUp ? (
        <>
          <MainBox
            style={{
              backgroundRepeat: router?.pathname === "/subscribe" && "repeat-y",
            }}
            className={isExcludePage ? " " : "hide-on-mobile"}
          >
            <div>
              <Header isPublic={props.isPublic} />
              {/* {props.isPublic === true ||
              isDashboard ||
              !isBusinessUser ||
              !props.children ? null : (
                <FruProBreadcrumbs />
              )} */}
              <Body>{props.children}</Body>
            </div>

            <Footer />
            {/* isExclude page only subscribe page message displayed none!! */}
            {isRestrictUser || isExcludePage ? null : <FloatingButton/>}
            {isRestrictUser || isExcludePage ? null : <Message/>}
            {user?.isVerified && renderRestrictMessage()}
            <FruProDialog
              size="xsmall"
              buttons={arrayButtons}
              open={!showedCookieConsent}
              onClose={() => {
                // always accept
                onAccept();
              }}
              buttonCenter
            >
              <ModalContainer>
                <img src={`/static/svg/cookie.svg`} alt="cookie" />
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "0.35em" }}
                >
                  Cookie consent
                </Typography>
                <Typography variant="subtitle2">
                  This website uses cookies to ensure you get the best
                  experience on our website.
                </Typography>
              </ModalContainer>
            </FruProDialog>
          </MainBox>
        </>
      ) : (
        <Root className={isExcludePage ? " " : "hide-on-mobile"}>
          <div>
            <Header isPublic={props.isPublic} />
            {props.isPublic === true ||
            isDashboard ||
            !isBusinessUser ||
            !props.children ? null : isRemoveBreadCrumb ? null : (
              <FruProBreadcrumbs />
            )}
            <Body>{props.children}</Body>
          </div>

          <Footer />
          {isRestrictUser || isExcludePage ? null : <FloatingButton/>}
          {isRestrictUser || isExcludePage ? null : <Message/>}
          {user?.isVerified && renderRestrictMessage()}
          <FruProDialog
            size="xsmall"
            buttons={arrayButtons}
            open={!showedCookieConsent}
            onClose={() => {
              // always accept
              onAccept();
            }}
            buttonCenter
          >
            <ModalContainer>
              <img src={`/static/svg/cookie.svg`} alt="cookie" />
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "0.35em" }}
              >
                Cookie consent
              </Typography>
              <Typography variant="subtitle2">
                This website uses cookies to ensure you get the best experience
                on our website.
              </Typography>
            </ModalContainer>
          </FruProDialog>
        </Root>
      )}
      {!isExcludePage && <DownloadApp />}
    </>
  );
}
