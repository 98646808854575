import { useRouter } from "next/router";
import React, { useState } from "react";
import { Action, Fab } from "react-tiny-fab";

const FloatingButton = () => {
  const router = useRouter();
  const [isActionCreateHover, setIsActionCreateHover] = useState(false);
  const [isActionUploadHover, setIsActionUploadHover] = useState(false);

  return (
    <Fab
      mainButtonStyles={{ background: "#000"}}
      icon={<img src="/static/svg/VectorPlus.svg" />}
      alwaysShowTitle={true}
    >
      <Action
        onMouseEnter={() => setIsActionCreateHover(true)}
        onMouseLeave={() => setIsActionCreateHover(false)}
        style={{
          background: "#000",
          opacity: isActionCreateHover ? "0.5" : "1",
        }}
        onClick={() => router.push("/create-new-surplus")}
        text="New Allocation"
      >
        <img src="/static/svg/ic_order.svg" />
      </Action>
      <Action
        onMouseEnter={() => setIsActionUploadHover(true)}
        onMouseLeave={() => setIsActionUploadHover(false)}
        style={{
          background: "#000",
          opacity: isActionUploadHover ? "0.5" : "1",
        }}
        onClick={() => router.push("/document-AI-invoices")}
        text="Upload New Invoice"
      >
        <img src="/static/svg/Import_light_white.svg" />
      </Action>
      {/* // Using a custom component for this one. See another example in "example/src/index.js"
    <SomeCustomComponent
      text="Foobar!"
      onClick={handleTheFooBarOnClick}
    >
        <i className="fa fa-foo-bar-fa-foo" />
      </SomeCustomComponent> */}
    </Fab>
  );
};

export default FloatingButton;
