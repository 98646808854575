import dynamic from "next/dynamic";

const FruProButton = dynamic(() => import("@components/Button"), {
  ssr: false,
});

const FruProDialog = dynamic(() => import("@components/Dialog"), {
  ssr: false,
});

const FruProChip = dynamic(() => import("@components/Chip"), {
  ssr: false,
});

const FruProBreadcrumbs = dynamic(() => import("@components/Breadcrumbs"), {
  ssr: false,
});

const FruProTextField = dynamic(() => import("@components/TextField"), {
  ssr: false,
});

const FruProControllerTextField = dynamic(
  () => import("@components/ControllerTextField"),
  {
    ssr: false,
  }
);

const FruProControllerPasswordField = dynamic(
  () => import("@components/ControllerPasswordField/ControllerPasswordField"),
  {
    ssr: false,
  }
);

const FruProFormSection = dynamic(() => import("@components/FormSection"), {
  ssr: false,
});

const FruProChipSelection = dynamic(() => import("@components/ChipSelection"), {
  ssr: false,
});

const FruProControllerAutocomplete = dynamic(
  () => import("@components/ControllerAutocomplete"),
  {
    ssr: false,
  }
);

const FruProControllerSwitch = dynamic(() => import("@components/Switch"), {
  ssr: false,
});

const FruProUpload = dynamic(() => import("@components/Upload"), {
  ssr: false,
});

const FruProDataGrid = dynamic(() => import("@components/DataGrid"), {
  ssr: false,
});

const FruText = dynamic(() => import("@components/Text"), {
  ssr: false,
});

const FruProCropImage = dynamic(() => import("@components/CropImage"), {
  ssr: false,
});

const FruProCurrencyTextField = dynamic(
  () => import("@components/CurrencyInput"),
  {
    ssr: false,
  }
);

const AsyncAutoCompleteInfiniteScroll = dynamic(
  () =>
    import(
      "@components/AsyncAutoCompleteInfiniteScroll/AsyncAutoCompleteInfiniteScroll"
    ),
  {
    ssr: false,
  }
);

const AsyncAutoComplete = dynamic(
  () => import("@components/AsyncAutoComplete/AsyncAutoComplete"),
  {
    ssr: false,
  }
);

const FruProActionDropdown = dynamic(
  () => import("@components/ActionDropdown/ActionDropdown"),
  {
    ssr: false,
  }
);

const FruProLoadingProduces = dynamic(
  () => import("@components/LoadingProduces/LoadingProduces"),
  {
    ssr: false,
  }
);

const FruproControllerComboBox = dynamic(
  () => import("@components/ControllerComboBox/ControllerComboBox"),
  {
    ssr: false,
  }
);

const FruProMenuList = dynamic(() => import("@components/MenuList/MenuList"), {
  ssr: false,
});

const FruProConfirmModal = dynamic(
  () => import("@components/ConfirmModal/ConfirmModal"),
  {
    ssr: false,
  }
);

const FruProEmptyData = dynamic(
  () => import("@components/EmptyData/EmptyData"),
  {
    ssr: false,
  }
);

const FruProLimitedCompanyModal = dynamic(() =>
  import("@components/LimitedCompanyModal/LimitedCompanyModal")
);

const MainMenuAccordion = dynamic(() =>
  import("@components/MainMenuAccordion/MainMenuAccordion")
);
const FruProTagInput = dynamic(() => import("@components/TagInput/TagInput"));
const FruProFilterBadgeCarousel = dynamic(() =>
  import("@components/FilterBadgeCarousel/FilterBadgeCarousel")
);

export {
  FruProButton,
  FruProChip,
  FruProBreadcrumbs,
  FruProTextField,
  FruProFilterBadgeCarousel,
  FruProDialog,
  FruProFormSection,
  FruProChipSelection,
  FruProControllerTextField,
  FruProControllerAutocomplete,
  FruProControllerSwitch,
  FruProUpload,
  FruProDataGrid,
  FruText,
  FruProCurrencyTextField,
  FruProTagInput,
  AsyncAutoCompleteInfiniteScroll,
  AsyncAutoComplete,
  FruProCropImage,
  FruProActionDropdown,
  FruProLoadingProduces,
  FruproControllerComboBox,
  FruProMenuList,
  FruProConfirmModal,
  FruProEmptyData,
  FruProControllerPasswordField,
  FruProLimitedCompanyModal,
  MainMenuAccordion,
};
