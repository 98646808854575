import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Typography,
  Popover,
  Grid,
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
  Link,
  Button,
} from "@material-ui/core";
import { FruProButton, FruProMenuList, FruText } from "@components";
import { useStyles } from "./styles";
import ListChannel from "./ListChannel";
import Chat from "./Chat";
import ChatActions from "./ChatActions";
import {
  createChannel,
  getListChannels,
  sendMessage,
  setCurrentUser,
  listenChannels,
  makeReadMessage,
  getMessageHistory,
  receiveMessage,
  formatFirebaseMessage,
  getMoreMessage,
  syncGetListChannel,
  addNewUserToChannel,
  renameChannel,
  updateChannelAdmins,
  removeUserFromChannel,
  getMoreMessageByMessageId,
  sendBroadcastMessage,
  syncSendBroadcastMessage,
  getChannelsByIDs,
} from "@services/chat";
import { getConnections } from "@services/connections";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsFirstTimeGetChannelsSuccess,
  setNewChatInfo,
  setNewMessageCount,
  setReduxChannels,
  setBroadcastChannels as setBroadcastChannelsAction,
} from "@reducers/chat";
import { getCompany } from "@services/company";
import AddNewChatDialog from "./AddNewChatDialog";
import MediaSlider from "./MediaSlider";
import { v4 as uuidv4 } from "uuid";
import GroupUsersDialog from "./GroupUsersDialog";
import clsx from "clsx";
import RenameChannelDialog from "./RenameChannelDialog";
import SearchMessageContainer from "./SearchMessageContainer";
import InfiniteScrollChannels from "./InfiniteScrollChannels";
import ShareProductDialog from "./ShareProductDialog";
import _, { uniqWith, isEqual } from "lodash";
import { CHAT_SIDE_BAR_KEYS, CHAT_SIDE_BAR_ITEMS } from "./constant";
import { COMPANY_STATUS_ENUM, CONNECTION_STATUS_ENUM } from "@constants";
import toastr from "toastr";

export default function Message() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [openAddBroadcastDialog, setOpenAddBroadcastDialog] = useState(false);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [openAddUserBroadcastDialog, setOpenAddUserBroadcastDialog] =
    useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const openSearchMsgContainerRef = useRef();
  const [openSearchMsgContainer, setOpenSearchMsgContainer] = useState(false);
  openSearchMsgContainerRef.current = openSearchMsgContainer;
  const [initSearchMsg, setInitSearchMsg] = useState("");
  const [removeFixed, setRemoveFixed] = useState(false);

  const btnRef = useRef();

  const channelsRef = useRef();
  const broadcastChannelsRef = useRef();
  const archivedChannelsRef = useRef();
  const currentChannelRef = useRef();
  const anchorElRef = useRef();
  const messageInputRef = useRef();
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);
  const newChatInfo = useSelector((state) => state.chat.newChatInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  anchorElRef.current = anchorEl;

  const [channels, setChannels] = useState([]);
  channelsRef.current = channels;

  const [broadcastChannels, setBroadcastChannels] = useState([]);
  broadcastChannelsRef.current = broadcastChannels;

  const [archivedChannels, setArchivedChannels] = useState([]);
  archivedChannelsRef.current = channels;

  const [currentChannel, setCurrentChannel] = useState();
  currentChannelRef.current = currentChannel;

  const [channelAnchorEl, setChannelAnchorEl] = useState(null);

  const [messages, setMessages] = useState([]);
  const [repliedMessage, setRepliedMessage] = useState(null);

  const messagesRef = useRef();
  messagesRef.current = messages;

  const [creatingChannel, setCreatingChannel] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  const [currentMediaIndex, setCurrentMediaIndex] = useState();

  const [gettingMore, setGettingMore] = useState(false);
  const gettingMoreRef = useRef();
  gettingMoreRef.current = gettingMore;
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreNew, setHasMoreNew] = useState(true);

  const [infiniteScrollScreen, setInfiniteScrollScreen] = useState({
    isShow: false,
    group: "",
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openReplyInfo, setOpenReplyInfo] = useState(false);

  const [activeTab, setActiveTab] = useState(CHAT_SIDE_BAR_ITEMS[0].key);
  const [selectedProduces, setSelectedProduces] = useState([]);
  const [openShareProductDialog, setOpenShareProductDialog] = useState(false);

  const isCompanyVerified =
    user?.companyStatus === COMPANY_STATUS_ENUM.VERIFIED;

  useEffect(() => {
    dispatch(setReduxChannels(channels));
  }, [channels]);

  useEffect(() => {
    dispatch(setBroadcastChannelsAction(broadcastChannels));
  }, [broadcastChannels]);

  useEffect(() => {
    window.onscroll = function () {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 54
      ) {
        setRemoveFixed(true);
      } else {
        setRemoveFixed(false);
      }
    };
  }, []);

  const isBroadcastChannel = useMemo(() => {
    return activeTab === CHAT_SIDE_BAR_KEYS.BROADCAST;
  }, [activeTab]);

  const membersInfo = useMemo(() => {
    if (!currentChannel?.id) return undefined;

    let archivedData = archivedChannels.find(
      (c) => c.firebaseChannelId === currentChannel?.id
    );

    if (!archivedData) return currentChannel.membersInfo;

    let newMembers = Object.keys(currentChannel.membersInfo).map((key) => {
      let syncPhoto = (archivedData.members || []).find(
        (i) => i.id === key && i?.photo?.url
      );
      let member = {
        ...currentChannel.membersInfo[key],
      };
      if (syncPhoto?.photo) member.photo = syncPhoto?.photo;
      return member;
    });

    return Object.assign(
      {},
      ...newMembers.map((member) => ({
        [member.id]: member,
      }))
    );
  }, [currentChannel, archivedChannels]);

  const getArchivedChannels = () => {
    // get Channels by api
    syncGetListChannel({
      type: "NORMAL",
      limit: 9999,
    }).then((res) => {
      setArchivedChannels(res?.items || []);
      // get Firestore's Channels detail
      // res?.items?.length > 0 &&
      getListChannels(user?.id).then((_res) => {
        const transformedRes = _res
          ?.filter((_i) => !_i?.isBroadcast)
          .map((_i) => {
            let archivedData =
              res?.items.find((__i) => __i.firebaseChannelId === _i.id) || _i;

            let validPictures = (archivedData.members || []).filter(
              (i) => i.id !== user?.id && i?.photo?.url
            );

            let isInactivated = archivedData?.status === "INACTIVATED";
            let syncMembersInfo = _i?.membersInfo || {};

            Object.keys(syncMembersInfo).map((key) => {
              let syncPhoto = (archivedData.members || []).find(
                (i) => i.id === key && i?.photo?.url
              );
              if (syncPhoto?.photo)
                syncMembersInfo[key].photo = syncPhoto?.photo;
            });

            // if channel type === "COMPANY", use company logo for channel avatar
            let data = {
              ..._i,
              channelPicture:
                (validPictures.length === 0
                  ? ""
                  : validPictures[0]?.photo?.url) || _i?.channelPicture,
              isInactivated,
              membersInfo: syncMembersInfo,
            };
            if (archivedData?.type === "COMPANY") {
              data.type = "COMPANY";
              data.isCompany = true;
              data.companyLogo = archivedData?.photo?.thumbUrl;
            }
            return data;
          });

        setChannels(transformedRes);

        if (currentChannel) {
          setCurrentChannel(transformedRes[0]);
        }

        dispatch(setIsFirstTimeGetChannelsSuccess(true));
      });
    });
  };

  const getArchivedBroadcastChannels = () => {
    // get Channels by api
    syncGetListChannel({
      type: "BROADCAST",
      limit: 9999,
    }).then((res) => {
      setArchivedChannels(res?.items || []);
      // get Firestore's Channels detail
      // res?.items?.length > 0 &&
      getListChannels(user?.id).then((_res) => {
        const transformedRes = _res
          .filter((_i) => _i?.isBroadcast)
          .map((_i) => {
            let archivedData =
              res?.items.find((__i) => __i.firebaseChannelId === _i.id) || _i;
            let validPictures = (archivedData.members || []).filter(
              (i) => i.id !== user?.id && i?.photo?.url
            );
            let isInactivated = archivedData?.status === "INACTIVATED";
            let syncMembersInfo = _i?.membersInfo || {};

            Object.keys(syncMembersInfo).map((key) => {
              let syncPhoto = (archivedData.members || []).find(
                (i) => i.id === key && i?.photo?.url
              );
              if (syncPhoto?.photo)
                syncMembersInfo[key].photo = syncPhoto?.photo;
            });

            // if channel type === "COMPANY", use company logo for channel avatar
            let data = {
              ..._i,
              channelPicture:
                (validPictures.length === 0
                  ? ""
                  : validPictures[0]?.photo?.url) || _i.channelPicture,
              isInactivated,
              membersInfo: syncMembersInfo,
            };
            if (archivedData?.type === "COMPANY") {
              data.type = "COMPANY";
              data.isCompany = true;
              data.companyLogo = archivedData?.photo?.thumbUrl;
            }
            return data;
          });

        setBroadcastChannels(transformedRes);

        if (currentChannel) {
          setCurrentChannel(transformedRes[0]);
        }
      });
    });
  };

  useEffect(() => {
    setCurrentUser({
      ...user,
      companyName: company?.companyName,
    });
    listenChannels(user?.id, onChannelUpdate);
    getArchivedChannels();
    getArchivedBroadcastChannels();
  }, []);

  useEffect(() => {
    if (currentChannel?.id) {
      setGettingMore(false);
      setHasMore(true);
      receiveMessage(currentChannel?.id, onReceiveNewMsg);
      getMessageHistory(currentChannel?.id).then((res) => {
        if (res.length > 0) {
          if (currentChannel.pendingProductAttachment) {
            // add a pending product message
            const newPendingMessage = formatFirebaseMessage(
              "",
              null,
              undefined,
              "",
              currentChannel.pendingProductAttachment
            );
            let messageUUID = uuidv4();
            setMessages([
              {
                ...newPendingMessage,
                isPending: true,
                messageUUID,
              },
              ...res,
            ]);
          } else setMessages(res);
          scrollToBottom();
        }
      });
      // if (currentChannel?.unReadUsers && anchorEl)
      if (currentChannel?.unReadUsers?.[user?.id] && anchorEl) {
        makeReadMessage(
          user?.id,
          currentChannel?.id,
          currentChannel?.members,
          {},
          currentChannel?.isBroadcast
        );
        setChannels(
          channels.map((i) =>
            i.id !== currentChannel?.id
              ? i
              : {
                  ...i,
                  unReadUsers: {
                    ...i.unReadUsers,
                    [user?.id]: 0,
                  },
                }
          )
        );
      }
    } else if (currentChannel) {
      setMessages([]);
    }
    setOpenReplyInfo(false);
  }, [currentChannel?.id]);

  const onReceiveNewMsg = async (newMess) => {
    // if on search mode, only need to reset messages history
    if (openSearchMsgContainerRef.current) {
      await resetMessagesOnSearchMode();
      return scrollToBottom();
    }
    let newMessages = [newMess, ...messagesRef.current];
    newMessages = newMessages.filter(
      (i) =>
        !i.isPending ||
        !newMessages.some(
          (c) => !c.isPending && c.messageUUID === i.messageUUID
        )
    );
    setMessages(newMessages);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    const chatContainer = document.getElementById("chat-list");
    if (!chatContainer) return;
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  const scrollToTopWithGutter = (getNew) => {
    const chatContainer = document.getElementById("chat-list");
    if (!chatContainer) return;
    if (!getNew) chatContainer.scrollTop = 30;
    else chatContainer.scrollTop = chatContainer.scrollHeight - 368 - 40;
  };

  const onChannelUpdate = (data) => {
    let currentChannels = data?.isBroadcast
      ? broadcastChannelsRef.current
      : channelsRef.current;

    if ([...currentChannels].find((channel) => channel.id === data.id)) {
      let newChannels = [...currentChannels];
      newChannels = newChannels
        .filter(
          (i) =>
            !i.isPending ||
            !newChannels.some(
              (c) => !c.isPending && c.channelUUID === i.channelUUID
            )
        )
        .map((i) =>
          i.id === data.id
            ? {
                ...i,
                ...data,
                channelPicture: i.channelPicture || "",
                membersInfo: i.membersInfo || data.membersInfo,
              }
            : i
        )
        .filter((i) => i.type !== "removed"); // remove deleted channel

      if (data?.isBroadcast) {
        setBroadcastChannels(newChannels);
      } else {
        setChannels(newChannels);
      }
    } else {
      let newChannels = [data, ...currentChannels];
      newChannels = newChannels
        .filter(
          (i) =>
            !i.isPending ||
            !newChannels.some(
              (c) => !c.isPending && c.channelUUID === i.channelUUID
            )
        )
        .filter((i) => i.type !== "removed"); // remove deleted channel;

      if (data?.isBroadcast) {
        setBroadcastChannels(newChannels);
      } else {
        setChannels(newChannels);
      }
    }

    // update currentChannel if the same id
    if (currentChannelRef.current?.id === data.id && anchorElRef.current) {
      if (data.type === "removed") {
        return setCurrentChannel();
      }
      delete data.type; // remove firestore action type
      let newCurrentChannel = {
        ...currentChannelRef.current,
        ...data,
      };
      setCurrentChannel(newCurrentChannel);
      // clear unReadUsers if current in this chat room and unReadUsers > 0
      if (data?.unReadUsers?.[user?.id] > 0) {
        makeReadMessage(
          user?.id,
          newCurrentChannel.id,
          newCurrentChannel.members,
          data,
          newCurrentChannel?.isBroadcast
        );
      }
    }
  };

  const getExistChannel = (memberId) => {
    return channels.length === 0
      ? undefined
      : [...channels, ...broadcastChannels].find(
          (c) =>
            c?.members?.length === 2 &&
            c?.members?.includes(memberId) &&
            !c.isGroupChannel &&
            !c.companyId
        );
  };

  // when user click chat button on produce detail or on header chat list
  useEffect(() => {
    if (!newChatInfo) return;
    else if (newChatInfo.onlyToggleChatPanel) {
      resetReduxNewChatInfo();
      // open chat panel
      handleClick({
        currentTarget: btnRef.current,
      });
    } else {
      let existChannel;
      // check if newChatInfo has channelUUID
      if (newChatInfo.channelUUID) {
        if (newChatInfo?.isBroadcast) {
          existChannel =
            broadcastChannels.length === 0
              ? undefined
              : broadcastChannels.find(
                  (c) => c?.channelUUID === newChatInfo.channelUUID
                );
        } else {
          existChannel =
            channels.length === 0
              ? undefined
              : channels.find(
                  (c) => c?.channelUUID === newChatInfo.channelUUID
                );
        }
      } else {
        // check if there is an exist 1-1 channel between 2 users
        existChannel = getExistChannel(newChatInfo?.id);
      }
      if (!existChannel) {
        // init a blank channel
        let tmpChatInfo;
        if (Array.isArray(newChatInfo))
          tmpChatInfo = {
            channelUUID: uuidv4(),
            newChatInfo,
            isArray: true,
          };
        else
          tmpChatInfo = {
            ...newChatInfo,
            channelUUID: uuidv4(),
            isArray: false,
          };
        createChannel(
          { ...user, companyName: company?.companyName },
          Array.isArray(newChatInfo) ? { newChatInfo } : newChatInfo,
          true,
          Array.isArray(newChatInfo),
          {
            isBroadcast: Array.isArray(newChatInfo)
              ? !!newChatInfo[0]?.isBroadcast
              : !!newChatInfo?.isBroadcast,
          }
        ).then((newBlankChannel) => {
          if (newBlankChannel?.isBroadcast) {
            setBroadcastChannels([
              {
                ...newBlankChannel,
                pendingTargetUser: tmpChatInfo,
                channelUUID: tmpChatInfo?.channelUUID || "",
              },
              ...broadcastChannels,
            ]);
          } else {
            setChannels([
              {
                ...newBlankChannel,
                pendingTargetUser: tmpChatInfo,
                channelUUID: tmpChatInfo?.channelUUID || "",
              },
              ...channels,
            ]);
          }

          setCurrentChannel({
            ...newBlankChannel,
            pendingTargetUser: tmpChatInfo,
            channelUUID: tmpChatInfo?.channelUUID || "",
          });
          // if newBlankChannel as productAttachment info
          if (newBlankChannel.productAttachment) {
            // add a pending message before create new channel
            const newPendingMessage = formatFirebaseMessage(
              "",
              null,
              undefined,
              "",
              newBlankChannel.productAttachment
            );
            let messageUUID = uuidv4();
            setMessages([
              {
                ...newPendingMessage,
                isPending: true,
                messageUUID,
              },
              ...messagesRef.current,
            ]);
          }
        });
      } else {
        // if newBlankChannel as productAttachment info
        if (newChatInfo.productAttachment) {
          setCurrentChannel({
            ...existChannel,
            pendingProductAttachment: newChatInfo.productAttachment,
          });
        } else setCurrentChannel(existChannel);
      }

      resetReduxNewChatInfo();
      // open chat panel
      handleClick({
        currentTarget: btnRef.current,
      });
    }
  }, [newChatInfo]);

  const resetReduxNewChatInfo = () => dispatch(setNewChatInfo(null));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentChannel();
    setMessages([]);
    setSearchKeyword("");
    setInitSearchMsg("");
    setOpenSearchMsgContainer(false);
    setActiveTab(CHAT_SIDE_BAR_KEYS.CHAT);
  };

  const bulkSendProductMessages = async (
    channelData = currentChannel,
    lastMessageCreateAt
  ) => {
    let pendingProducts = messagesRef.current?.filter(
      (message) => message.isPending && message.type === "product"
    );
    if (
      pendingProducts &&
      pendingProducts?.length > 0 &&
      !channelData?.isBroadcast
    ) {
      const listRes = await Promise.all(
        pendingProducts.map(async (pendingProduct) => {
          let rs = await sendMessage(
            channelData.id,
            "",
            channelData.members,
            null,
            pendingProduct?.messageUUID,
            pendingProduct?.productAttachment,
            lastMessageCreateAt ? lastMessageCreateAt - 1 : null
          );
          return rs;
        })
      );
    }
    return;
  };

  // if on search mode, reset message history before sending or receiving new message
  const resetMessagesOnSearchMode = () => {
    return new Promise((resolve, reject) => {
      if (!openSearchMsgContainerRef.current) {
        resolve();
      } else {
        getMessageHistory(currentChannel?.id).then((res) => {
          if (res.length > 0) {
            if (currentChannel.pendingProductAttachment) {
              // add a pending product message
              const newPendingMessage = formatFirebaseMessage(
                "",
                null,
                undefined,
                "",
                currentChannel.pendingProductAttachment
              );
              let messageUUID = uuidv4();
              setMessages([
                {
                  ...newPendingMessage,
                  isPending: true,
                  messageUUID,
                },
                ...res,
              ]);
            } else setMessages(res);
          }
          resolve();
        });
      }
    });
  };

  const createNewChannel = async (
    currentUser,
    targetUser,
    isPending,
    isArray,
    channelTypes
  ) => {
    const channelData = await createChannel(
      currentUser,
      targetUser,
      isPending,
      isArray,
      channelTypes
    );

    return channelData;
  };

  const onSendMsg = async (message, file, productAttachments) => {
    if (!currentChannel?.channelName)
      return toastr.info("Please select a channel");
    const res = await resetMessagesOnSearchMode();
    // add a pending message before create new channel
    const newPendingMessage = formatFirebaseMessage(message, file);
    let messageUUID = uuidv4();
    setMessages([
      {
        ...newPendingMessage,
        isPending: true,
        messageUUID,
      },
      ...messagesRef.current,
    ]);
    scrollToBottom();
    if (currentChannel?.isPending) {
      setCreatingChannel(true);
      // create new channel
      let channelData = await createNewChannel(
        { ...user, companyName: company?.companyName },
        currentChannel.pendingTargetUser,
        false,
        currentChannel?.pendingTargetUser?.isArray,
        {
          isBroadcast: currentChannel?.isBroadcast,
        }
      );
      setCreatingChannel(false);
      setCurrentChannel(channelData);
      setTimeout(async () => {
        await bulkSendProductMessages(channelData, newPendingMessage.createAt);
        // send message
        if (currentChannel?.isBroadcast) {
          handleSendBroadcastMessage({
            channelData,
            message,
            file,
            productAttachments,
            messageUUID,
          });
        } else {
          sendMessage(
            channelData.id,
            message,
            channelData.members,
            file,
            messageUUID
          );
        }
      }, 100);
    } else {
      await bulkSendProductMessages();
      if (currentChannel?.isBroadcast) {
        handleSendBroadcastMessage({
          channelData: currentChannel,
          message,
          file,
          messageUUID,
          productAttachments,
        });
      } else {
        sendMessage(
          currentChannel.id,
          message,
          currentChannel.members,
          file,
          messageUUID,
          null,
          null,
          repliedMessage
        );
      }
    }
    setOpenReplyInfo(false);
    setRepliedMessage(null);
  };

  const handleSendBroadcastMessage = async ({
    channelData,
    message,
    file,
    productAttachments,
    messageUUID,
  }) => {
    let createAt = Date.now();
    if (productAttachments) {
      for (const productAttachment of productAttachments) {
        const productMessageUUID = uuidv4();

        const messageData = await sendBroadcastMessage(
          channelData.id,
          "",
          channelData.members,
          file,
          productMessageUUID,
          productAttachment
        );

        createAt = messageData?.createAt;
      }

      // Send messages to each participants
      handleSendMessagesToParticipants(
        channelData,
        { message, createAt },
        file,
        productAttachments
      );
    } else {
      const messageData = await sendBroadcastMessage(
        channelData.id,
        message,
        channelData.members,
        file,
        messageUUID
      );

      createAt = messageData?.createAt;

      // Send messages to each participants
      handleSendMessagesToParticipants(
        channelData,
        { message, createAt },
        file,
        productAttachments,
        messageData
      );
    }
  };

  const handleSendMessagesToParticipants = async (
    channelData,
    message,
    file,
    productAttachments,
    broadcastMessageData
  ) => {
    let firebaseMessages = broadcastMessageData
      ? [
          {
            firebaseMessageId: broadcastMessageData.id,
            firebaseChannelId: channelData.id,
          },
        ]
      : [];

    const newMembers = channelData?.members?.filter(
      (member) => member !== user?.id
    );

    for (const member of newMembers) {
      //check if there is an exist 1-1 channel between broadcaster and participants
      const existChannel = getExistChannel(member);
      let broadcastMessageUUID = uuidv4();

      if (existChannel) {
        // Send message to participant
        let messageData = {};
        if (productAttachments) {
          productAttachments?.forEach(async (productAttachment) => {
            const broadcastMessageUUID = uuidv4();
            await sendMessage(
              existChannel.id,
              "",
              [user?.id, member],
              null,
              broadcastMessageUUID,
              productAttachment,
              null,
              null,
              true
            );
          });
        } else {
          messageData = await sendMessage(
            existChannel.id,
            message?.message,
            [user?.id, member],
            file,
            broadcastMessageUUID,
            null,
            null,
            null,
            true
          );
        }

        firebaseMessages.push({
          firebaseMessageId: messageData.firebaseMessageId,
          firebaseChannelId: existChannel.id,
        });
      } else {
        // create new channel
        let channelUUID = uuidv4();
        await createNewChannel(
          { ...user, companyName: company?.companyName },
          { ...currentChannel.membersInfo[member], channelUUID },
          false,
          false,
          {
            isBroadcast: false,
          }
        )
          .then(async (res) => {
            // Send message
            let messageData = {};
            if (productAttachments) {
              productAttachments?.forEach(async (productAttachment) => {
                const broadcastMessageUUID = uuidv4();
                await sendMessage(
                  res.id,
                  "",
                  [user?.id, member],
                  null,
                  broadcastMessageUUID,
                  productAttachment,
                  null,
                  null,
                  true
                );
              });
            } else {
              messageData = await sendMessage(
                res.id,
                message?.message,
                res.members,
                file,
                broadcastMessageUUID,
                null,
                null,
                null,
                true
              );
            }

            firebaseMessages.push({
              firebaseMessageId: messageData.firebaseMessageId,
              firebaseChannelId: res.id,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // channelData?.members
    //   ?.filter((member) => member !== user?.id)
    //   .forEach(async (member, index) => {});

    syncSendBroadcastMessage(firebaseMessages, {
      message: message?.message,
      files: file,
      createAt: message?.createAt,
    });
  };

  const handleGetMoreMessage = async (getNew) => {
    if (currentChannel?.id) {
      setGettingMore(true);
      scrollToTopWithGutter(getNew);
      let moreMessages = await getMoreMessage(currentChannel.id, getNew);
      setGettingMore(false);
      let uniquedMessages = !getNew
        ? [...messagesRef.current, ...moreMessages]
        : [...moreMessages, ...messagesRef.current];
      uniquedMessages = uniqWith(uniquedMessages, isEqual);
      setMessages(uniquedMessages);
      if (moreMessages?.length <= 0) {
        if (!getNew) setHasMore(false);
        else setHasMoreNew(false);
      }
    }
  };

  const handleScroll = (e) => {
    if (!e.currentTarget.scrollTop && !gettingMoreRef.current && hasMore) {
      console.log("get older messages");
      handleGetMoreMessage();
    }
    // 368px is the hard height value of chat list
    const hasMoreNewThreshold = 30;
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 368 <
        hasMoreNewThreshold &&
      !gettingMoreRef.current &&
      hasMoreNew
    ) {
      console.log("get newer messages");
      handleGetMoreMessage(true);
    }
  };

  const handleAddNewUserToChannel = async (newUser) => {
    let res = await addNewUserToChannel(
      currentChannel.id,
      newUser,
      currentChannel.members,
      membersInfo,
      currentChannel
    );

    if (res?.isSuccess) {
      let newChannels = await getChannelsByIDs(user?.id, [currentChannel.id]);

      setCurrentChannel(newChannels[0]);
    }
  };

  const handAddNewUserToBroadcastChannel = (newUser) => {
    const transformNewUsers = newUser?.map((item) => item?.user);
    addNewUserToChannel(
      currentChannel.id,
      transformNewUsers,
      currentChannel.members,
      membersInfo,
      currentChannel
    );
  };

  const handleRenameChannel = (newChannelName) =>
    renameChannel(currentChannel, newChannelName, currentChannel.members);

  const handleUpdateChannelAdmin = (newAdminIds, message) =>
    updateChannelAdmins(
      currentChannel.id,
      newAdminIds,
      message,
      currentChannel.members
    );

  const handleRemoveUserFromChannel = (removedUser) =>
    removeUserFromChannel(
      currentChannel.id,
      removedUser,
      currentChannel.members,
      membersInfo,
      currentChannel.adminIds,
      currentChannel.isRenamed,
      currentChannel?.isBroadcast
    );
  const onClickSearchIcon = () => {
    setInitSearchMsg("");
    setOpenSearchMsgContainer(true);
  };

  const handleJumpToSearchedMessage = (channelId, messageId) => {
    getMoreMessageByMessageId(channelId, messageId).then((invertedMessages) => {
      setMessages([...invertedMessages].reverse());
      // reset all hasmore flags
      setHasMoreNew(true);
      setHasMore(true);
      scrollToTopWithGutter(true);
      if (invertedMessages?.length <= 6) handleGetMoreMessage(true);
    });
  };

  const handleSelectChannel = (channel, keyword) => {
    setCurrentChannel(channel);
    if (keyword) {
      setInitSearchMsg(keyword);
      setOpenSearchMsgContainer(true);
    } else setInitSearchMsg("");
  };

  const handleCreateBroadcast = (selectedUsers) => {
    if (selectedUsers?.length <= 0) return;
    if (selectedUsers?.length === 1) {
      dispatch(
        setNewChatInfo({
          companyId: selectedUsers[0].user.companyId,
          email: selectedUsers[0].user.email,
          fullName: selectedUsers[0].user.fullName,
          id: selectedUsers[0].user.id,
          photo: selectedUsers[0].user.photo,
          companyName: selectedUsers[0]?.user?.companyName,
          isBroadcast: true,
        })
      );
    } else {
      dispatch(
        setNewChatInfo(
          selectedUsers.map((item) => ({
            companyId: item.user.companyId,
            email: item.user.email,
            fullName: item.user.fullName,
            id: item.user.id,
            photo: item.user.photo,
            companyName: item?.user?.companyName,
            isBroadcast: true,
          }))
        )
      );
    }
  };

  const onShareProducts = () => {
    onSendMsg("", null, selectedProduces);
    setOpenShareProductDialog(false);
    setSelectedProduces([]);
  };

  const onCheckedProduce = (produce) => {
    const produceIndex = selectedProduces?.findIndex(
      (item) => item.id === produce.id
    );

    if (produceIndex >= 0) {
      const newSelectedProduces = [...selectedProduces];
      newSelectedProduces.splice(produceIndex, 1);

      setSelectedProduces(newSelectedProduces);
    } else {
      setSelectedProduces((prevState) => [produce, ...prevState]);
    }
  };

  const open = Boolean(anchorEl);

  const newMessageChannelCount = useMemo(() => {
    return channels.filter((i) => !i?.isPending && i?.unReadUsers?.[user?.id])
      .length;
  }, [channels]);

  // isGroupChat || number of users > 2
  const isGroupChannel = useMemo(
    () => currentChannel?.isGroupChat || currentChannel?.members?.length > 2,
    [currentChannel]
  );

  const onClickChannelDetails = (e) => {
    setChannelAnchorEl(e.currentTarget);
  };

  const renderStoreLink = useCallback(() => {
    if (isGroupChannel) return null;
    else {
      let { membersInfo = {}, members = [] } = currentChannel;
      const archivedChannel = archivedChannels?.find(
        (item) => item?.firebaseChannelId === currentChannel.id
      );
      const archivedChannelMembers = archivedChannel?.members?.filter(
        (item) => item?.id !== user?.id
      );

      members = members.filter((i) => i !== user?.id);
      // if (members <= 0) return null;

      let storeLink = `/c/${membersInfo[members[0]]?.companySlug}`;

      if (membersInfo[members[0]]?.companyId === user?.companyId) {
        storeLink = `/my-company?isFilter=true&userIds[0]=${members[0]}`;
      }

      return (
        <Box>
          <Typography className={clsx(classes.textGrey, "fw-bold text-xs")}>
            {membersInfo[members[0]]?.companyName ||
              archivedChannelMembers?.[0]?.company?.companyName}
            <span>{" - "}</span>
            {currentChannel?.channelName ? (
              <Link href={storeLink}>
                <Typography
                  className={clsx({
                    [classes.link]: true,
                    "fw-bold": true,
                  })}
                  component="span"
                  color="primary"
                >
                  View company store
                </Typography>
              </Link>
            ) : null}
          </Typography>
        </Box>
      );
    }
  }, [currentChannel, isGroupChannel]);

  useEffect(() => {
    dispatch(setNewMessageCount(newMessageChannelCount));
  }, [newMessageChannelCount]);

  const shouldDisableButton =
    creatingChannel ||
    currentChannel?.isPending ||
    currentChannel?.isInactivated;

  const CHANNEL_DETAILS_OPTIONS = [
    {
      icon: <img src={`/static/svg/ic_mute_chat.svg`} />,
      label: "Mute chat",
      onClick: () => console.log("Mute chat"),
    },
    {
      icon: <img src={`/static/svg/ic_archived_chat.svg`} />,
      label: "Archive chat",
      onClick: () => console.log("Archive chat"),
    },
  ];

  const GROUP_CHANNEL_DETAILS_OPTIONS = [
    {
      icon: <img src={`/static/svg/ic_group_name.svg`} />,
      label: isBroadcastChannel
        ? "Change broadcast list name"
        : "Change group name",
      onClick: () => setOpenRenameDialog(true),
    },
    {
      icon: <img src={`/static/svg/ic_users.svg`} />,
      label: "View members",
      onClick: () => setOpenUsersDialog(true),
    },
    {
      icon: <img src={`/static/svg/ic_add_user.svg`} />,
      label: "Add user",
      onClick: () => {
        switch (activeTab) {
          case CHAT_SIDE_BAR_KEYS.CHAT:
            setOpenAddUserDialog(true);
            break;
          case CHAT_SIDE_BAR_KEYS.BROADCAST:
            setOpenAddUserBroadcastDialog(true);
            break;
          default:
            break;
        }
      },
    },
  ];

  return (
    <div
      className={clsx({
        [classes.messageContainer]: true,
        [classes.absolute]: removeFixed,
      })}
    >
      <Button
        style={{ display: "none" }}
        className={classes.messageBtn}
        color="primary"
        variant="contained"
        onClick={handleClick}
        ref={btnRef}
      >
        <img
          src={`/static/svg/ic_chat.svg`}
          alt="ic sms"
          height="16"
          width="16"
        />
        <Typography className={classes.text}>
          Messages
          {newMessageChannelCount
            ? ` (${
                newMessageChannelCount <= 9 ? newMessageChannelCount : "9+"
              })`
            : null}
        </Typography>
      </Button>
      <Popover
        className={clsx({
          [classes.popoverChat]: true,
          [classes.maximizeScreen]: !!currentChannel,
        })}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Grid container wrap="nowrap" className={classes.popup}>
          <Grid item xs={currentChannel ? 4 : 12}>
            {openSearchMsgContainer ? (
              <SearchMessageContainer
                open={openSearchMsgContainer}
                initSearchMsg={initSearchMsg}
                handleClose={setOpenSearchMsgContainer}
                firebaseChannelId={currentChannel?.id}
                onSelectedMessage={handleJumpToSearchedMessage}
              />
            ) : infiniteScrollScreen.isShow ? (
              <InfiniteScrollChannels
                channels={channels}
                channel={currentChannel}
                onClickChannel={handleSelectChannel}
                userId={user?.id}
                onClickNewMessage={() => setOpenAddDialog(true)}
                membersInfo={membersInfo}
                setInfiniteScrollScreen={setInfiniteScrollScreen}
                infiniteScrollScreen={infiniteScrollScreen}
                searchKeyword={searchKeyword}
              />
            ) : (
              <ListChannel
                channels={isBroadcastChannel ? broadcastChannels : channels}
                channel={currentChannel}
                onClickChannel={handleSelectChannel}
                userId={user?.id}
                onClickNewMessage={() => setOpenAddDialog(true)}
                onClickNewBroadcast={() => setOpenAddBroadcastDialog(true)}
                membersInfo={membersInfo}
                setInfiniteScrollScreen={setInfiniteScrollScreen}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
                handleClose={handleClose}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setCurrentChannel={setCurrentChannel}
                broadcastChannelsRef={broadcastChannelsRef}
                channelsRef={channelsRef}
              />
            )}
          </Grid>
          {currentChannel ? (
            <Grid item xs={8}>
              <Grid
                item
                xs={12}
                className={classes.channelHeader}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  style={{ padding: 0, marginRight: 20 }}
                  onClick={handleClose}
                >
                  <img src={`/static/svg/ic_close.svg`} alt="Close icon" />
                </IconButton>
              </Grid>
              <Grid
                item
                className={classes.popupHeader}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Tooltip
                    title={`${currentChannel?.channelName}
                    ${
                      currentChannel?.isInactivated ? " (Unemployed user)" : ""
                    }`}
                    placement="top"
                  >
                    <Box className={classes.channelName}>
                      <FruText fontWeight={500} className={classes.commonText}>
                        {currentChannel?.channelName}
                        {currentChannel?.isInactivated
                          ? " (Unemployed user)"
                          : ""}
                      </FruText>
                    </Box>
                  </Tooltip>
                  {isGroupChannel ? null : <Typography></Typography>}
                  {renderStoreLink()}
                </Box>
                {!currentChannel?.isPending && isGroupChannel && (
                  <Box>
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={onClickChannelDetails}
                      disabled={isBroadcastChannel && !isCompanyVerified}
                    >
                      <img
                        src={`/static/svg/ic_details_channel.svg`}
                        alt="Detail icon"
                      />
                    </IconButton>
                  </Box>
                )}
              </Grid>
              <Grid
                className={classes.rightInfoContent}
                id="chat-list"
                onScroll={handleScroll}
              >
                {gettingMore ? (
                  <Box
                    display="flex"
                    width={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={16} />
                  </Box>
                ) : null}
                {(currentChannel?.id || currentChannel?.productAttachment) && (
                  <Chat
                    messages={messages}
                    membersInfo={membersInfo}
                    userId={user?.id}
                    setCurrentMedia={setCurrentMedia}
                    setCurrentMediaIndex={setCurrentMediaIndex}
                    lastVisit={currentChannel?.lastVisit}
                    shouldShowName={isGroupChannel}
                    scrollToBottom={scrollToBottom}
                    messageInputRef={messageInputRef}
                    repliedMessage={repliedMessage}
                    setRepliedMessage={setRepliedMessage}
                    openReplyInfo={openReplyInfo}
                    setOpenReplyInfo={setOpenReplyInfo}
                    handleJumpToSearchedMessage={handleJumpToSearchedMessage}
                    currentChannel={currentChannel}
                  />
                )}
              </Grid>
              <Grid className={classes.actions}>
                <ChatActions
                  onSendMsg={onSendMsg}
                  disableSendChat={
                    (creatingChannel && currentChannel?.isPending) ||
                    currentChannel?.isInactivated ||
                    (isBroadcastChannel && !isCompanyVerified)
                  }
                  shouldDisableButton={shouldDisableButton}
                  onClickSearchIcon={onClickSearchIcon}
                  currentChannel={currentChannel}
                  messageInputRef={messageInputRef}
                  activeTab={activeTab}
                  setOpenShareProductDialog={setOpenShareProductDialog}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <FruProMenuList
          anchorEl={channelAnchorEl}
          handleClose={() => setChannelAnchorEl(null)}
          items={
            isGroupChannel
              ? GROUP_CHANNEL_DETAILS_OPTIONS
              : CHANNEL_DETAILS_OPTIONS
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </Popover>
      <AddNewChatDialog
        openDialog={openAddDialog}
        handleCloseDialog={setOpenAddDialog}
        userId={user?.id}
      />
      <AddNewChatDialog
        isOnlyNewUser
        openDialog={openAddUserDialog}
        handleCloseDialog={setOpenAddUserDialog}
        userId={user?.id}
        handleAdd={handleAddNewUserToChannel}
        membersInfo={membersInfo}
      />
      {openAddBroadcastDialog && (
        <AddNewChatDialog
          openDialog={openAddBroadcastDialog}
          handleCloseDialog={setOpenAddBroadcastDialog}
          userId={user?.id}
          handleAdd={handleCreateBroadcast}
          getData={getConnections}
          type={CHAT_SIDE_BAR_KEYS.BROADCAST}
          initialQueryParams={{
            companyStatus: COMPANY_STATUS_ENUM.VERIFIED,
            status: CONNECTION_STATUS_ENUM.ACCEPTED,
          }}
        />
      )}

      {openAddUserBroadcastDialog && (
        <AddNewChatDialog
          openDialog={openAddUserBroadcastDialog}
          handleCloseDialog={setOpenAddUserBroadcastDialog}
          userId={user?.id}
          handleAdd={handAddNewUserToBroadcastChannel}
          membersInfo={membersInfo}
          getData={getConnections}
          type={CHAT_SIDE_BAR_KEYS.BROADCAST}
          initialQueryParams={{
            companyStatus: COMPANY_STATUS_ENUM.VERIFIED,
            status: CONNECTION_STATUS_ENUM.ACCEPTED,
          }}
        />
      )}

      <GroupUsersDialog
        openDialog={openUsersDialog}
        handleCloseDialog={setOpenUsersDialog}
        membersInfo={membersInfo}
        adminIds={currentChannel?.adminIds}
        currentUserId={user?.id}
        handleChangeAdmin={handleUpdateChannelAdmin}
        handleRemoveUserFromChannel={handleRemoveUserFromChannel}
        isBroadcastChannel={currentChannel?.isBroadcast}
      />
      <RenameChannelDialog
        openDialog={openRenameDialog}
        handleCloseDialog={setOpenRenameDialog}
        currentChannelName={currentChannel?.channelName}
        handleRename={(newChannelName) => {
          setOpenRenameDialog(false);
          handleRenameChannel(newChannelName);
        }}
        isBroadcastChannel={currentChannel?.isBroadcast}
      />
      <ShareProductDialog
        open={openShareProductDialog}
        handleClose={() => {
          setOpenShareProductDialog(false);
          setSelectedProduces([]);
        }}
        onSubmit={onShareProducts}
        onChecked={onCheckedProduce}
        selecteds={selectedProduces}
      />
      <MediaSlider
        open={!!currentMedia}
        currentMedia={currentMedia}
        currentMediaIndex={currentMediaIndex}
        handleClose={() => {
          setCurrentMedia();
          setCurrentMediaIndex();
        }}
      />
    </div>
  );
}
